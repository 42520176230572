<template>
  <b-modal
    v-model="data.showModal"
    size="md"
    button-size="sm"
    modal-class="template-modal"
    title-class="w-full"
    footer-class="w-full"
    no-fade
    @show="onShow"
    @hide="onHide"
    hide-header-close
    scrollable
  >
    <!-- Modal header -->
    <div slot="modal-title">
      <div class="flex w-full justify-between">
        Account Details
      </div>
    </div>

    <!-- Modal content -->
    <div class="flex flex-col gap-2">
      <table
        class="table table-google table-highlight"
        :class="{ 'table-busy': isLoading }"
      >
        <tbody>
          <tr>
            <td>ID</td>
            <td>
              {{ account.id ?? 'New account' }}
            </td>
          </tr>
          <tr>
            <td>Login</td>
            <td>
              <b-form-input
                v-model="account.login"
                size="sm"
                autofocus
                autocomplete="off"
                trim
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <td>Password</td>
            <td>

              <b-input-group size="sm">
                <b-form-input
                  v-model="account.password"
                  autocomplete="off"
                  trim
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    title="Generate password"
                    @click="generatePassword()"
                  >
                    <i class="fas fa-sync"></i>
                  </b-button>
                </b-input-group-append>
              </b-input-group>

            </td>
          </tr>
          <tr>
            <td>Name</td>
            <td>
              <b-form-input
                v-model="account.display_name"
                size="sm"
                autocomplete="off"
                trim
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <td>Admin</td>
            <td>
              <button-select
                v-model="account.is_admin"
                :options="options.boolean"
                size="sm"
              />
            </td>
          </tr>
          <tr>
            <td>Blocked</td>
            <td>
              <button-select
                v-model="account.blocked"
                :options="options.boolean"
                size="sm"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal footer -->
    <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
      <div class="flex w-full justify-content-between">
        <div>
          <b-btn
            size="sm"
            variant="primary"
            :disabled="isLoading || !canSave"
            @click="onSave"
          >
            Save
          </b-btn>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');

export default {
  name: 'AccountDetails',
  components: {
    ButtonSelect,
  },
  props: {
    data: Object,
    id: Number,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (this.account.login.length === 0) return false;
      if (this.account.password.length === 0) return false;
      if (this.account.display_name.length === 0) return false;
      return true;
    },
    preventMessage() {
      return 'You have unsaved changes. Are you sure you want to close?';
    },
    preventClose() {
      return true;
    },
  },
  data() {
    return {
      account: {
        id: null,
        login: '',
        password: '',
        display_name: '',
        is_admin: false,
        blocked: false,
      },
      loadingCount: 0,
      filters: {},
      options: {
        boolean: [
          { value: false, text: 'No' },
          { value: true, text: 'Yes' },
        ],
      },
    };
  },
  methods: {
    generatePassword() {
      const newPassword = (Math.floor(Math.random() * (2147483647 - 1000000000 + 1)) + 1000000000)
        .toString(36);
      this.account.password = newPassword;
    },
    fetchAccount() {
      if (this.id === null) {
        this.generatePassword();
      } else {
        this.loadingCount++;
        this.$http
          .get(`/accounts/${this.id}`)
          .then((res) => {
            this.account = {
              ...res.body,
            };
          })
          .catch((err) => {
            this.$toast.error(`Failed to fetch account: ${err.response.text}`);
          })
          .finally(() => {
            this.loadingCount--;
          });
      }
    },
    onSave() {
      this.loadingCount++;
      this.$http
        .post('/accounts')
        .send({ account: this.account })
        .then(() => {
          this.$emit('updated');
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to send account: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onShow() {
      this.fetchAccount();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    onHide(event) {
      if (this.preventClose && !confirm(this.preventMessage)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
</style>
